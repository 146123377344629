import React from 'react'
import { DownOutlined, SmileOutlined } from '@ant-design/icons'
import { Dropdown, Menu, Image } from 'antd'
import Router, { useRouter } from 'next/router'
import Link from 'next/link'
import styled from 'styled-components'

const LanguageLink = styled.a`
  display: inline-flex;
  align-items: center;

  img {
    margin-right: 7.5px;
    width: 1.5em;
    height: auto;
  }
`
const MobileLanguageLink = styled.a`
  span {
    width: 100%;
    display: inline-block;
    margin: 10px;
    height: auto;
    color: #000;
  }

  span img {
    margin-right: 7.5px;
    width: 1.5em;
    height: auto;
  }
`

const slugToLabelMap = {
  et: 'Eesti keeles', // Estonian
  fi: 'Suomeksi', // Finnish
  ru: 'По-русски', // Russian
  de: 'Auf Deutsch', // German
  en: 'In English', // English
}
const slugToImgName = {
  et: 'est',
  fi: 'fin',
  ru: 'rus',
  de: 'ger',
  en: 'eng',
}

const getTranslatedPath = (locale, pathname) => {
  if (locale === 'et') {
    return pathname;
  }

  return `/${locale}${pathname}`;
}

const LanguageMenu = ({ slugs }) => {
  const router = useRouter()
  const menu = (
    <Menu>
      {Object.keys(slugToLabelMap).map((locale) => {
        let translatedPath = slugs && slugs[locale];
        let finalPath = '';
        if (translatedPath) {
          finalPath = getTranslatedPath(locale, translatedPath);
        } else {
          finalPath = getTranslatedPath(locale, router.asPath);
        }

        return (
          <Menu.Item key={`slug_map_${locale}`}>
              <LanguageLink href={finalPath}>
                <img src={`/static/langs/${slugToImgName[locale]}.png`} />
                {slugToLabelMap[locale]}
              </LanguageLink>
          </Menu.Item>
        )
      })}
    </Menu>
  )
  return (
    <Dropdown overlay={menu}>
      <LanguageLink><img src={`/static/langs/${slugToImgName[router.locale]}.png`} /> {slugToLabelMap[router.locale]}</LanguageLink>
    </Dropdown>
  )
}

export const LanguageMenuMobile = ({ slugs }) => {
  const router = useRouter()
  return (
    <>
      {Object.keys(slugToLabelMap).map((locale) => {
        let translatedPath = slugs && slugs[locale];
        let finalPath = '';
        if (translatedPath) {
          finalPath = getTranslatedPath(locale, translatedPath);
        } else {
          finalPath = getTranslatedPath(locale, router.asPath);
        }

        return (
          <Menu.Item>
            <MobileLanguageLink href={finalPath}>
              <span>
                <img src={`/static/langs/${slugToImgName[locale]}.png`} />
                {slugToLabelMap[locale]}
              </span>
            </MobileLanguageLink>
          </Menu.Item>
        )
      })}
    </>
  )
}

export default LanguageMenu
