import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import cart from './cart/reducer'
import modal from './modal/reducer'

const bindMiddleware = middleware => {
  if (process.env.NODE_ENV !== 'production') {
    const { composeWithDevTools } = require('redux-devtools-extension')
    return composeWithDevTools(applyMiddleware(...middleware))
  }
  return applyMiddleware(...middleware)
}

export const initStore = (initialState = {}) => {
  return createStore(
    combineReducers({
      cart,
      modal,
    }),
    initialState,
    bindMiddleware([thunkMiddleware])
  )
}
