module.exports = {
  et: {
    'cookiesTitle': 'Küpsised',
    'intro': 'Antud veebisait kasutab küpsiseid selleks, et pakkuda teile optimaalset veebikasutuse kogemust. Palume teil anda oma nõusolek, vajutades allolevale nupule "Aktsepteerin kõiki küpsiseid". Juhul kui soovite saada täiendavat teavet meie poolt kasutatavate küpsiste kohta ja soovite rakendada isikupärastatud küpsiste seadeid, palume teil tutvuda meie küpsiste kasutamise poliitikaga lähemalt.',
    'description': `Me kasutame oma veebisaidil "küpsiseid", millega võite nõustuda, kui otsustate meie veebisaiti kasutada. "Küpsised" on väikesed tekstifailid, mis laaditakse alla ja salvestatakse teie arvutisse või muusse seadmesse, kui külastate veebisaiti, ning aitavad meil muu hulgas parandada veebisaidil pakutavaid teenuseid ja muuta need teie jaoks mugavamaks.
    Kui külastate meie veebisaiti, nõustute "küpsiste" kasutamisega, millest teid teavitatakse teie esimesel veebisaidi külastusel kuvatava teavitusteksti kaudu. Meie kasutatavad "küpsised" jagunevad kolme liiki:`,
    'preference': 'Palun valige enda eelistus',
    'all': 'Nõustun kõikide küpsistega',
    'required': 'Vajalikud "küpsised"',
    'requiredExplanation': `Need võimaldavad teil pääseda juurde meie veebisaidi erinevatele osadele ja salvestatakse teie seadmesse, kui külastate veebisaiti. Need "küpsised" on veebisaidi toimimiseks hädavajalikud ega saa neid välja lülitada. Vajalikud "küpsised" paigaldatakse vastavalt teie tegevusele veebisaidil, näiteks vormide täitmisele. Nende "küpsiste" abil töötleme isikuandmeid meie õigustatud huvi alusel.`,
    'analytics': 'Analüütilised "küpsised"',
    'analyticsExplanation': `Need võimaldavad meil loendada veebisaidi külastusi ja liikluse allikaid, analüüsida teie külastusi veebisaidil, et saaksime mõõta ja parandada veebisaidi toimivust. Kui keeldute neist "küpsistest", siis teie külastust meie veebisaidi statistikas ei kajastata.`,
    'targeting': 'Sihtturu "küpsised"',
    'targetingExplanation': `Need võimaldavad olla teadlikud teie sisueelistustest veebisaidil, mis võimaldab meil pakkuda teile kõige asjakohasemat teavet meie toodete ja teenuste kohta, tõsta meie sihtturu turunduse kvaliteeti ja parandada kasutajakogemust meie veebisaidil. Teatud juhtudel edastame neid "küpsiseid" sotsiaalmeedia kanalitele vastavalt teie juhistele.`,
    'conclusion': `Teil on võimalik meie veebisaiti külastada ka ilma kõigi "küpsistega" nõustumata, muutes oma isikliku veebibrauseri privaatsusseadeid. Samuti saate kõik "küpsised", mis on seni teie seadmesse salvestatud, kustutada. Siiski, kui "küpsised" on keelatud või piiratud, ei pruugi kõik veebisaidi funktsioonid töötada.`,
    'agreeAll': 'Nõustun kõigiga',
    'agreeSelected': 'Nõustun selekteeritutega',
    'close': 'Sulge',
  },
  en: {
    'cookiesTitle': 'Cookies',
    'intro': 'This website uses cookies to provide you with the best possible browsing experience. Please give your consent by clicking the "Accept all cookies" button below. If you would like more information about the cookies we use and wish to apply personalized cookie settings, please review our cookie policy in detail.',
    'description': `We use "cookies" on our website, which you may accept if you choose to use our website. "Cookies" are small text files that are downloaded and stored on your computer or other device when you visit a website, and they help us, among other things, improve the services offered on the website and make them more convenient for you.
    When you visit our website, you agree to the use of "cookies," which you are informed about through a notification displayed on your first visit to the website. The "cookies" we use fall into three categories:`,
    'preference': 'Please choose your preference',
    'all': 'I accept all cookies',
    'required': 'Required "cookies"',
    'requiredExplanation': `These allow you to access different parts of our website and are stored on your device when you visit the website. These "cookies" are essential for the functioning of the website and cannot be disabled. Required "cookies" are installed based on your actions on the website, such as filling out forms. We process personal data using these "cookies" based on our legitimate interest.`,
    'analytics': 'Analytical "cookies"',
    'analyticsExplanation': `These allow us to count website visits and traffic sources, analyze your visits to the website, so we can measure and improve the performance of the website. If you decline these "cookies," your visit will not be reflected in our website statistics.`,
    'targeting': 'Targeting "cookies"',
    'targetingExplanation': `These allow us to be aware of your content preferences on the website, enabling us to offer you the most relevant information about our products and services, improve the quality of our targeted marketing, and enhance the user experience on our website. In certain cases, we share these "cookies" with social media channels according to your instructions.`,
    'conclusion': `You can visit our website without agreeing to all "cookies" by changing your personal web browser's privacy settings. You can also delete all "cookies" that have been stored on your device so far. However, if "cookies" are disabled or restricted, not all functions of the website may work.`,
    'agreeAll': 'I agree with all',
    'agreeSelected': 'I agree with the selected',
    'close': 'Close',
  },
  fi: {
    'cookiesTitle': 'Evästeet',
    'intro': 'Tämä verkkosivusto käyttää evästeitä tarjotakseen sinulle parhaan mahdollisen selauskokemuksen. Anna suostumuksesi napsauttamalla alla olevaa "Hyväksyn kaikki evästeet" -painiketta. Jos haluat lisätietoja käyttämistämme evästeistä ja haluat käyttää mukautettuja evästeasetuksia, tutustu evästekäytäntöömme tarkemmin.',
    'description': `Käytämme verkkosivustollamme "evästeitä", jotka voit hyväksyä, jos päätät käyttää verkkosivustoamme. "Evästeet" ovat pieniä tekstitiedostoja, jotka ladataan ja tallennetaan tietokoneellesi tai muulle laitteellesi, kun vierailet verkkosivustolla. Ne auttavat meitä muun muassa parantamaan verkkosivustolla tarjottavia palveluita ja tekemään ne sinulle käyttäjäystävällisemmiksi.
    Kun vierailet verkkosivustollamme, hyväksyt "evästeiden" käytön, mistä sinua tiedotetaan ensimmäisellä vierailullasi näytettävän ilmoitustekstin kautta. Käyttämämme "evästeet" jaetaan kolmeen luokkaan:`,
    'preference': 'Valitse oma mieltymyksesi',
    'all': 'Hyväksyn kaikki evästeet',
    'required': 'Välttämättömät "evästeet"',
    'requiredExplanation': `Näiden avulla voit käyttää verkkosivustomme eri osia, ja ne tallennetaan laitteellesi, kun vierailet verkkosivustolla. Nämä "evästeet" ovat välttämättömiä verkkosivuston toiminnalle, eikä niitä voi poistaa käytöstä. Välttämättömät "evästeet" asennetaan toimiesi perusteella verkkosivustolla, esimerkiksi lomakkeiden täyttämisen yhteydessä. Käsittelemme näiden "evästeiden" avulla henkilötietoja oikeutetun etumme perusteella.`,
    'analytics': 'Analytiikka "evästeet"',
    'analyticsExplanation': `Nämä mahdollistavat verkkosivuston vierailujen ja liikenteen lähteiden laskemisen, käyntiesi analysoinnin verkkosivustolla, jotta voimme mitata ja parantaa verkkosivuston suorituskykyä. Jos kieltäydyt näistä "evästeistä", vierailusi ei näy verkkosivustomme tilastoissa.`,
    'targeting': 'Kohdistus "evästeet"',
    'targetingExplanation': `Nämä mahdollistavat tietoisuuden sisällön mieltymyksistäsi verkkosivustolla, mikä mahdollistaa sen, että voimme tarjota sinulle ajankohtaisinta tietoa tuotteistamme ja palveluistamme, parantaa kohdistetun markkinoinnin laatua ja parantaa käyttäjäkokemusta verkkosivustollamme. Tietyissä tapauksissa jaamme nämä "evästeet" sosiaalisen median kanaville ohjeidesi mukaisesti.`,
    'conclusion': `Voit vierailla verkkosivustollamme myös hyväksymättä kaikkia "evästeitä" muuttamalla henkilökohtaisia verkkoselaimesi yksityisyysasetuksia. Voit myös poistaa kaikki tähän mennessä laitteellesi tallennetut "evästeet". Jos "evästeet" on kuitenkin poistettu käytöstä tai niitä on rajoitettu, kaikki verkkosivuston toiminnot eivät välttämättä toimi.`,
    'agreeAll': 'Hyväksyn kaikki',
    'agreeSelected': 'Hyväksyn valitut',
    'close': 'Sulje',
  },
  de: {
    'cookiesTitle': 'Cookies',
    'intro': 'Diese Website verwendet Cookies, um Ihnen das bestmögliche Surferlebnis zu bieten. Bitte geben Sie Ihre Zustimmung, indem Sie auf die Schaltfläche "Alle Cookies akzeptieren" unten klicken. Wenn Sie weitere Informationen über die von uns verwendeten Cookies wünschen und personalisierte Cookie-Einstellungen vornehmen möchten, lesen Sie bitte unsere Cookie-Richtlinie im Detail.',
    'description': `Wir verwenden auf unserer Website "Cookies", denen Sie zustimmen können, wenn Sie sich entscheiden, unsere Website zu nutzen. "Cookies" sind kleine Textdateien, die beim Besuch einer Website auf Ihren Computer oder ein anderes Gerät heruntergeladen und gespeichert werden und uns unter anderem helfen, die auf der Website angebotenen Dienste zu verbessern und benutzerfreundlicher zu gestalten.
    Wenn Sie unsere Website besuchen, stimmen Sie der Verwendung von "Cookies" zu, worüber Sie bei Ihrem ersten Besuch auf der Website durch eine Benachrichtigung informiert werden. Die von uns verwendeten "Cookies" sind in drei Kategorien unterteilt:`,
    'preference': 'Bitte wählen Sie Ihre Präferenz',
    'all': 'Ich akzeptiere alle Cookies',
    'required': 'Erforderliche "Cookies"',
    'requiredExplanation': `Diese ermöglichen Ihnen den Zugriff auf verschiedene Teile unserer Website und werden auf Ihrem Gerät gespeichert, wenn Sie die Website besuchen. Diese "Cookies" sind für das Funktionieren der Website unerlässlich und können nicht deaktiviert werden. Erforderliche "Cookies" werden entsprechend Ihren Aktivitäten auf der Website installiert, z. B. beim Ausfüllen von Formularen. Mit diesen "Cookies" verarbeiten wir personenbezogene Daten auf der Grundlage unseres berechtigten Interesses.`,
    'analytics': 'Analytische "Cookies"',
    'analyticsExplanation': `Diese ermöglichen es uns, Website-Besuche und Verkehrsquellen zu zählen, Ihre Besuche auf der Website zu analysieren, damit wir die Leistung der Website messen und verbessern können. Wenn Sie diese "Cookies" ablehnen, wird Ihr Besuch nicht in unserer Website-Statistik erfasst.`,
    'targeting': 'Zielgerichtete "Cookies"',
    'targetingExplanation': `Diese ermöglichen es uns, Ihre Inhaltspräferenzen auf der Website zu erkennen, was es uns ermöglicht, Ihnen die relevantesten Informationen über unsere Produkte und Dienstleistungen anzubieten, die Qualität unseres zielgerichteten Marketings zu verbessern und das Benutzererlebnis auf unserer Website zu optimieren. In bestimmten Fällen geben wir diese "Cookies" gemäß Ihren Anweisungen an soziale Medien weiter.`,
    'conclusion': `Sie können unsere Website auch besuchen, ohne allen "Cookies" zuzustimmen, indem Sie die Datenschutzeinstellungen Ihres persönlichen Webbrowsers ändern. Sie können auch alle "Cookies" löschen, die bisher auf Ihrem Gerät gespeichert wurden. Wenn "Cookies" jedoch deaktiviert oder eingeschränkt sind, funktionieren möglicherweise nicht alle Funktionen der Website.`,
    'agreeAll': 'Ich stimme allen zu',
    'agreeSelected': 'Ich stimme den ausgewählten zu',
    'close': 'Schließen',
  },
  ru: {
    'cookiesTitle': 'Файлы cookie',
    'intro': 'Этот веб-сайт использует файлы cookie, чтобы предоставить вам оптимальный опыт использования. Пожалуйста, дайте свое согласие, нажав кнопку "Принять все файлы cookie" ниже. Если вы хотите получить дополнительную информацию о файлах cookie, которые мы используем, и хотите настроить персонализированные параметры файлов cookie, ознакомьтесь с нашей политикой использования файлов cookie.',
    'description': `Мы используем файлы "cookie" на нашем веб-сайте, которые вы можете принять, если решите использовать наш веб-сайт. "Файлы cookie" — это небольшие текстовые файлы, которые загружаются и сохраняются на вашем компьютере или другом устройстве при посещении веб-сайта и помогают нам, среди прочего, улучшать предоставляемые на веб-сайте услуги и делать их более удобными для вас.
    Когда вы посещаете наш веб-сайт, вы соглашаетесь на использование "файлов cookie", о чем вас информируют с помощью уведомления, отображаемого при первом посещении веб-сайта. Используемые нами "файлы cookie" делятся на три категории:`,
    'preference': 'Пожалуйста, выберите свои предпочтения',
    'all': 'Я принимаю все файлы cookie',
    'required': 'Обязательные "файлы cookie"',
    'requiredExplanation': `Они позволяют вам получить доступ к различным частям нашего веб-сайта и сохраняются на вашем устройстве при посещении веб-сайта. Эти "файлы cookie" необходимы для работы веб-сайта и не могут быть отключены. Обязательные "файлы cookie" устанавливаются в соответствии с вашими действиями на веб-сайте, например, при заполнении форм. Мы обрабатываем персональные данные с помощью этих "файлов cookie" на основании нашего законного интереса.`,
    'analytics': 'Аналитические "файлы cookie"',
    'analyticsExplanation': `Они позволяют нам подсчитывать посещения веб-сайта и источники трафика, анализировать ваши посещения веб-сайта, чтобы мы могли измерять и улучшать производительность веб-сайта. Если вы откажетесь от этих "файлов cookie", ваш визит не будет отображен в нашей статистике веб-сайта.`,
    'targeting': 'Целевые "файлы cookie"',
    'targetingExplanation': `Они позволяют нам учитывать ваши предпочтения контента на веб-сайте, что позволяет нам предлагать вам наиболее актуальную информацию о наших продуктах и услугах, улучшать качество нашего целевого маркетинга и повышать удобство использования нашего веб-сайта. В некоторых случаях мы передаем эти "файлы cookie" социальным сетям в соответствии с вашими инструкциями.`,
    'conclusion': `Вы можете посещать наш веб-сайт и без согласия на использование всех "файлов cookie", изменив настройки конфиденциальности вашего веб-браузера. Вы также можете удалить все "файлы cookie", которые были сохранены на вашем устройстве ранее. Однако, если "файлы cookie" отключены или ограничены, не все функции веб-сайта могут работать.`,
    'agreeAll': 'Я согласен со всеми',
    'agreeSelected': 'Я согласен с выбранными',
    'close': 'Закрыть',
  }      
  
}
