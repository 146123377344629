import React, { useEffect } from "react";
import styled from "styled-components";
import { useSelector, shallowEqual } from "react-redux";
import Link from "next/link";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { CloseO } from "@styled-icons/evil";

import { openModal, closeModal } from "../store/modal/actions";

import { BREAKPOINTS } from "../constants";

const ModalWrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 999;
`;

const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
`;

const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.4),
    0px 2px 7px 2px rgba(0, 0, 0, 0.4);
  max-width: 98vw;
  max-height: 98vh;
  background-color: #fff;
  overflow: hidden;

  @media (max-width: ${BREAKPOINTS.MD}) {
    width: 100%;
  }
`;

const ModalContent = styled.div`
  height: auto;
  max-height: 98vh;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
`;

const Close = styled(CloseO)`
  width: 50px;
  height: 50px;
  color: #b35353;
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 5px;
  background-color: #fff;
  z-index: 10;

  @media (min-width: ${BREAKPOINTS.MD}) {
    display: none;
  }
`;

const Modal = ({ children, itemsInCart, openModal, closeModal }) => {
  return (
    <ModalWrapper>
      <ModalBackdrop onClick={() => closeModal()} />
      <ModalContainer>
        <ModalContent>
          <Close onClick={() => closeModal()} />
          {children}
        </ModalContent>
      </ModalContainer>
    </ModalWrapper>
  );
};

const mapStateToProps = (state) => ({
  itemsInCart: state.itemsInCart,
});

const mapDispatchToProps = (dispatch) => {
  return {
    closeModal: bindActionCreators(closeModal, dispatch),
    openModal: bindActionCreators(openModal, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
