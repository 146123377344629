import { cartActionTypes } from './actions.js'

const cartInitialState = {
};

export default (state = cartInitialState, action) => {
  switch (action.type) {
    case cartActionTypes.add:
      return Object.assign({}, state, {
        [action.item.id]: action.item
      })
    case cartActionTypes.remove:
      const newState = Object.assign({}, state);

      delete newState[action.itemID];

      return newState;
    case cartActionTypes.clear:
      return {};
    default:
      return state
  }
}
