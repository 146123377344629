import { modalActionTypes } from './actions.js'

const modalInitialState = {
  open: false
};

export default (state = modalInitialState, action) => {
  switch (action.type) {
    case modalActionTypes.open:
      return Object.assign({}, state, {
        open: true,
        id: action.id
      });
    case modalActionTypes.close:
      return Object.assign({}, state, {
        open: false
      });
    default:
      return state
  }
}
