import React, { useState, useContext } from "react";
import styled from "styled-components";
import { CloseO } from "@styled-icons/evil";

import translations from "../../config/translations";
import LanguageContext from "../../contexts/Language";

import { bindActionCreators } from "redux";
import { removeFromCart, addToCart } from "../../store/cart/actions";
import { connect } from "react-redux";

import { BREAKPOINTS } from "../../constants";
import { Form, InputNumber, Switch, Button, DatePicker } from "antd";

const CartButtonsWrapper = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const CloseButton = styled.div`
  font-size: 12px;
  width: auto;
  height: auto;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 1;
  border-radius: 5px;
  background-color: #c72424;
  color: #fff;
  padding: 0px 5px 0px 10px;

  &:hover {
    opacity: 1;
  }
`;

const Close = styled(CloseO)`
  width: 24px;
  height: 24px;
  color: #fff;
`;

const FormProduct = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 40px;
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 30px;
  margin-bottom: 30px;

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-template-columns: 1fr;
  }
`;
const FormProductContent = styled.div`
  width: 100%;
`;
const FormProductImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 6px;
`;
const FormProductHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.MD}) {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;
  }
`;
const FormProductValuesWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  @media (max-width: ${BREAKPOINTS.MD}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`;

const FormProductValuesSizeQtys = styled.ul`
  display: flex;
  flex-direction: column;
`;

const UploadedImages = styled.div`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: wrap;

  & > * {
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;

const UploadedImg = styled.img`
  width: 100px;
  height: auto;
`;

const UploadedImageWrapper = styled.div`
  position: relative;
  svg {
    position: absolute;
    color: red;
    border-width: ;
    background-color: #fff;
    border-radius: 50px;
    right: 0;
    opacity: 0;
  }

  &:hover {
    svg {
      opacity: 0.8;

      &:hover {
        opacity: 1;
      }
    }
  }
`;

const FormProductComponent = ({ product, removeFromCart, addToCart }) => {
  const lang = useContext(LanguageContext);
  const [currentProduct, setCurrentProduct] = useState({ ...(product || {}) });
  const [customiseProduct, setCustomiseProduct] = useState(false);
  const multiSizeQuantity = typeof product.quantity !== 'number'

  const changeProductValue = (field, value) => {
    setCurrentProduct({
      ...currentProduct,
      [field]: value,
    });

    addToCart({
      ...currentProduct,
      [field]: value,
    });
  };
  const removeImageFromCart = (imageData) => {
    const currentValues = currentProduct.uploadedImagesData;
    const newValues =
      currentValues && currentValues.length > 0
        ? currentValues.filter(
            (d) => d.uid !== imageData.uid && d.url !== imageData.url
          )
        : [];

    changeProductValue(
      "uploadedImagesData",
      JSON.parse(JSON.stringify(newValues))
    );
  };

  return (
    <FormProduct>
      <FormProductImage src={product.thumbnail} />

      <FormProductContent>
        <FormProductHeaderWrapper>
          <h3>{product.title}</h3>

          {!multiSizeQuantity && 
            <div>
              {translations["Set custom values"][lang]}
              <Switch
                style={{ marginLeft: "15px" }}
                onClick={() => setCustomiseProduct(!customiseProduct)}
                checked={customiseProduct}
              />
            </div>
          }
        </FormProductHeaderWrapper>

        <div>
          {multiSizeQuantity &&
            <FormProductValuesWrapper>
              {product.id}
              <span>{translations["Quantity"][lang]}</span>
              <FormProductValuesSizeQtys>
              {Object.keys(product.quantity)?.map(size => (
                <li key={size}>
                  <span>{size}, </span>
                  <span>{product.quantity[size]}</span>
                </li>
              ))}
              </FormProductValuesSizeQtys>
            </FormProductValuesWrapper>
          }
          {!multiSizeQuantity &&
            <FormProductValuesWrapper>
              {product.id}
              <span>{translations["Quantity"][lang]}</span>
              <InputNumber
                size={"small"}
                disabled={!customiseProduct}
                placeholder={product.quantity || 1}
                onChange={(value) => changeProductValue("quantity", value)}
              />
            </FormProductValuesWrapper>
          }
          <FormProductValuesWrapper>
            <div>Üleslaetud pildid</div>
            <UploadedImages>
              {currentProduct?.uploadedImagesData?.map((imageData) => (
                <UploadedImageWrapper>
                  <Close onClick={() => removeImageFromCart(imageData)} />
                  <UploadedImg src={imageData.url} alt={imageData.uid} />
                </UploadedImageWrapper>
              ))}
            </UploadedImages>
          </FormProductValuesWrapper>
          {/* <FormProductValuesWrapper>
            <span>{translations['Delivery date'][lang]}</span>
            <DatePicker
              size={'small'}
              disabled={!customiseProduct}
              placeholder="2015/01/01"
              onChange={(value) => changeProductValue('deliveryDate', value)}
            />
          </FormProductValuesWrapper> */}
        </div>

        <CartButtonsWrapper>
          <CloseButton onClick={() => removeFromCart(product.id)}>
            {translations["Remove"][lang]} <Close />
          </CloseButton>
        </CartButtonsWrapper>
      </FormProductContent>
    </FormProduct>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    removeFromCart: bindActionCreators(removeFromCart, dispatch),
    addToCart: bindActionCreators(addToCart, dispatch),
  };
};

export default connect(null, mapDispatchToProps)(FormProductComponent);
