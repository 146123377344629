import React from "react";
import { Provider } from "react-redux";
import { initStore } from "../store/store";
import App from "next/app";

export const withRedux = (PageComponent, { ssr = true } = {}) => {
  const WithRedux = ({ initialReduxState, ...props } = {}) => {
    const store = getOrInitStore(initialReduxState);

    // Whenever something changes in store,
    // we should store in localstorage to be used later
    store.subscribe(() => {
      localStorage.setItem("kaubad-state", JSON.stringify(store.getState()));
    });

    return (
      <Provider store={store}>
        <PageComponent {...props} />
      </Provider>
    );
  };

  // Make sure people don't use this HOC on _app.js level
  if (process.env.NODE_ENV !== "production") {
    const isAppHoc =
      PageComponent === App || PageComponent.prototype instanceof App;
    if (isAppHoc) {
      throw new Error("The withRedux HOC only works with PageComponents");
    }
  }

  // Set the correct displayName in development
  if (process.env.NODE_ENV !== "production") {
    const displayName =
      PageComponent.displayName || PageComponent.name || "Component";

    WithRedux.displayName = `withRedux(${displayName})`;
  }

  return WithRedux;
};

let reduxStore;

const addCartItemsFromLocalstorage = (initialState) => {
  let initialStateFromStorage = localStorage.getItem("kaubad-state");
  initialStateFromStorage =
    initialStateFromStorage && JSON.parse(initialStateFromStorage);

  const combinedInitialState = { ...initialState };
  const haveCartItemsInStorage =
    initialStateFromStorage &&
    initialStateFromStorage.cart &&
    Object.values(initialStateFromStorage.cart).length;

  if (haveCartItemsInStorage) {
    combinedInitialState.cart = initialStateFromStorage.cart;
  }

  return combinedInitialState;
};

const getOrInitStore = (initialState) => {
  // Always make a new store if server, otherwise state is shared between requests
  if (typeof window === "undefined") {
    return initStore(initialState);
  }

  // Create store if unavailable on the client and set it on the window object
  if (!reduxStore) {
    reduxStore = initStore(addCartItemsFromLocalstorage(initialState));
  }

  return reduxStore;
};
