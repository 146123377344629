export function shouldRedirect(redirections, currentPath) {  
    // Check if the current path matches any of the redirections rules.
    const redirectionRule = redirections.find(r => r.url === currentPath);
    if (redirectionRule) {
      return {
        destination: redirectionRule.destination, // Assuming you have a destination field or adjust accordingly.
        code: redirectionRule.code, // If the code is 301, it's a permanent redirect, else it's temporary.
      };
    }

    return;
}