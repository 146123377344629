import { useState, useEffect } from 'react';
import useWindowDimensions from './use-window-dimensions';
import { BREAKPOINTS } from '../constants';

export default function isMobile() {
	const { width } = useWindowDimensions();
	const [isWidthMobile, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(width < parseInt(BREAKPOINTS.MD));
	}, [width]);

	return isWidthMobile;
}
