import { useContext } from "react";
import Link from "next/link";
import styled from "styled-components";
import { BREAKPOINTS } from "../constants";

import translations from "../config/translations";
import LanguageContext from "../contexts/Language";
import CustomLink from "./custom-link";

const FooterWrapper = styled.div`
  position: relative;
  width: 100%;
  background-color: #4b5068;
`;

const FooterInner = styled.div`
  width: 100%;
  padding: 35px;
  max-width: 1240px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 35px;
  grid-row-gap: 35px;
  grid-auto-rows: min-content;
  box-sizing: border-box;

  @media (max-width: ${BREAKPOINTS.MD}) {
    grid-template-columns: 1fr;
    text-align: center;
  }
`;

const FooterContent = styled.div`
  font-size: 14px;
`;

const FooterTitle = styled.p`
  font-weight: 600;
  color: white;
  margin-bottom: 20px;
`;

const FooterLink = styled.a`
  color: white;
  display: block;
  margin: 14px 0;

  &:hover {
    color: gray;
  }
`;

const FooterText = styled.p`
  color: white;
  display: block;
  margin: 14px 0;
`;

export default function Footer({ items }) {
  const lang = useContext(LanguageContext);

  return (
    <FooterWrapper>
      <FooterInner>
        <FooterContent>
          <FooterTitle>{translations["Menu"][lang]}</FooterTitle>
          {items?.menu?.map((item, index) => (
            <CustomLink href={item.link} key={index}>
              <FooterLink>{item.name}</FooterLink>
            </CustomLink>
          ))}
        </FooterContent>
        <FooterContent>
          <FooterTitle>{translations["Partners"][lang]}</FooterTitle>
          {items?.partners?.map((item, index) => (
            <CustomLink href={item.link} key={index} passHref={true}>
              <FooterLink>{item.name}</FooterLink>
            </CustomLink>
          ))}
        </FooterContent>
        <FooterContent>
          <FooterTitle>Dot Holding OÜ</FooterTitle>
          {items?.company?.map((item, index) => (
            <FooterText key={index}>{item.name}</FooterText>
          ))}
        </FooterContent>
        <FooterContent>
          <FooterTitle>{translations["Social"][lang]}</FooterTitle>
          {items?.social?.map((item, index) => (
            <CustomLink href={item.link} key={index} passHref={true}>
              <FooterLink>{item.name}</FooterLink>
            </CustomLink>
          ))}
        </FooterContent>
      </FooterInner>
    </FooterWrapper>
  );
}
