export const modalActionTypes = {
  open: 'OPEN',
  close: 'CLOSE',
}

export const openModal = (id) => dispatch => {
  return dispatch({
    type: modalActionTypes.open,
    id: id || null
  })
}

export const closeModal = () => dispatch => {
  return dispatch({
    type: modalActionTypes.close
  })
}
