import React, { useState, useEffect, useContext  } from 'react'
import { Helmet } from 'react-helmet'
import { Button, Switch } from 'antd'
import styled from 'styled-components'
import Modal from './../modal'
import LanguageContext from "../../contexts/Language"
import translations from "./translations"
import { Description } from '@styled-icons/material'

const Background = styled.div`
  width: auto;
  height: auto;
  max-width: 900px;
  background-color: #fff;
  padding: 40px;
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;

//   @media (max-width: ${BREAKPOINTS.MD}) {
//     width: 100%;
//     height: auto;
//     padding: 20px;
//   }
`

const analyticsScripts = {
  gTag: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-56616547-5');
    `,
  facebook: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '952281729846125');
        fbq('track', 'PageView');
    `,
}

export default function CookieConsentDialog() {
  const lang = useContext(LanguageContext);
  const [isOpen, setIsOpen] = useState(true)
  const [consent, setConsent] = useState({
    analytics: false,
    essential: true,
    marketing: false,
    all: false,
  })

  useEffect(() => {
    // Check if the user has already given consent
    const savedConsent = localStorage.getItem('cookieConsent')
    if (!savedConsent) {
      setIsOpen(true)
    } else {
      setIsOpen(false);
      setConsent(JSON.parse(savedConsent))
    }
  }, [])

  useEffect(() => {
    if (consent.analytics) {
      // Add Google Analytics script
      if (!document.querySelector(`#gTagManager`)) {
        const gtagScript = document.createElement('script')
        gtagScript.src =
        gtagScript.id = 'gTagManager';
          'https://www.googletagmanager.com/gtag/js?id=UA-56616547-5'
        gtagScript.async = true
        document.body.appendChild(gtagScript)
      }

      for (const [key, scriptCode] of Object.entries(analyticsScripts)) {  
        if (!document.querySelector(`#${key}`)) {
            const scriptEl = document.createElement('script')
            scriptEl.id = key;
            scriptEl.innerHTML = scriptCode;
            document.body.appendChild(scriptEl)
        }
      }
    }
  }, [consent.analytics])

  const handleConsentChange = (type) => {
    setConsent((prev) => {
        const newConsent = { ...prev, [type]: !prev[type] };

        if (type === 'all') {
            const newValue = !prev.all;
            return {
                analytics: newValue,
                essential: newValue, // Always true
                marketing: newValue,
                all: newValue,
            };
        }

        newConsent.all = newConsent.analytics && newConsent.essential && newConsent.marketing;

        return newConsent;
    });
  }

  const acceptAllCookies = () => {
    const allConsent = {
      analytics: true,
      essential: true,
      marketing: true,
      all: true,
    }
    setConsent(allConsent)
    localStorage.setItem('cookieConsent', JSON.stringify(allConsent))
    setIsOpen(false)
  }

  const acceptSelectedCookies = () => {
    localStorage.setItem('cookieConsent', JSON.stringify(consent))
    setIsOpen(false)
  }

  const closeDialog = () => {
    setIsOpen(false)
  }

  return (
    <>
      <Helmet>
        {consent.analytics && (
          <meta
            name="google-site-verification"
            content="hQYsmnnhLvVXcRI1hsJLbnqT8ie8CMNTmR5WWj7_Jvo"
          />
        )}
        {consent.analytics && (
          <script
            async
            src="https://www.googletagmanager.com/gtag/js?id=UA-56616547-5"
          ></script>
        )}
      </Helmet>
      {isOpen ? (
        <Modal>
          <Background>
            <div className="cookie-consent-dialog">
              <div className="cookie-consent-content">
                <h2>{translations?.[lang]?.cookiesTitle}</h2>
                <p>
                {translations?.[lang]?.intro}
                </p>

                <p>
                {translations?.[lang]?.description}
                </p>
                <p><strong>{translations?.[lang]?.preference}:</strong></p>
                <div>
                  <label>
                    <Switch
                      checked={consent.all}
                      onChange={() => handleConsentChange('all')}
                    />
                    <strong>{translations?.[lang]?.all}</strong>
                  </label>
                </div>
                <p></p>
                <div>
                  <label>
                    <Switch
                      checked={consent.essential}
                      onChange={() => handleConsentChange('essential')}
                    />
                    <strong>{translations?.[lang]?.required}</strong>
                  </label>
                  <p>
                  {translations?.[lang]?.requiredExplanation}
                  </p>
                </div>
                <div>
                  <label>
                    <Switch
                      checked={consent.analytics}
                      onChange={() => handleConsentChange('analytics')}
                    />
                    <strong>{translations?.[lang]?.analytics}</strong>
                  </label>
                  <p>
                  {translations?.[lang]?.analyticsExplanation}
                  </p>
                </div>
                <div>
                  <label>
                    <Switch
                      checked={consent.marketing}
                      onChange={() => handleConsentChange('marketing')}
                    />
                    <strong>{translations?.[lang]?.targeting}</strong>
                  </label>
                  <p>
                  {translations?.[lang]?.targetingExplanation}
                  </p>
                </div>
                <p>
                {translations?.[lang]?.conclusion}
                </p>
                <div className="cookie-consent-actions">
                  <Button type="primary" onClick={acceptAllCookies}>{translations?.[lang]?.agreeAll}</Button>
                  <Button onClick={acceptSelectedCookies}>
                  {translations?.[lang]?.agreeSelected}
                  </Button>
                  <Button onClick={closeDialog}>{translations?.[lang]?.close}</Button>
                </div>
              </div>
            </div>
          </Background>
        </Modal>
      ) : null}
    </>
  )
}
