export const cartActionTypes = {
  add: 'ADD',
  remove: 'REMOVE',
  clear: 'CLEAR',
}

export const addToCart = item => dispatch => {
  return dispatch({
    type: cartActionTypes.add,
    item
  })
}

export const removeFromCart = itemID => dispatch => {
  return dispatch({
    type: cartActionTypes.remove,
    itemID
  })
}

export const clearCart = () => dispatch => {  
  return dispatch({
    type: cartActionTypes.clear
  })
}
