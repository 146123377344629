import React, { useContext } from "react";
import styled from "styled-components";
import { MAIN_COLOR } from "../constants";
import LanguageContext from "../contexts/Language";

import { Button } from "antd";
import Link from "next/link";

import { List } from "antd";

const StyledButton = styled.button`
  width: auto;
  height: auto;
  background-color: ${(props) => (props.bgColor ? props.bgColor : MAIN_COLOR)};
  color: white;
  font-size: 16px;
  padding: 12px 24px;
  border: none;
  border-radius: 8px;
  text-align: center;
  outline: none;
`;

const ResultItem = styled.li`
  width: 100%;
  max-height: 75px;
  height: 75px;
  display: flex;
  flex-wrap: nowrap;
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  cursor: pointer;
  opacity: 0.6;
  overflow: hidden;

  &:first-of-type {
    border-top: 0;
  }

  &:hover {
    opacity: 1;
  }
`;

const ResultLink = styled.span`
  font-size: 14px;
  color: #000;
  display: inline-block;
  flex: 0 1 auto;
  margin-left: 20px;
`;

const ResultImage = styled.img`
  width: 75px;
  height: auto;
  align-self: flex-start;
`;

export default function SearchResults({ results, onClickOnClose }) {
  const lang = useContext(LanguageContext);
  const SearchHeader = () => (
    <div style={{ textAlign: "right" }}>
      <Button
        onClick={() => onClickOnClose()}
        style={{ textAlign: "center" }}
        type="danger"
        size="small"
        shape="circle"
      >
        x
      </Button>
    </div>
  );
  const getLangPrefix = (lang) => (lang === "et" ? "" : `/${lang}`);

  return (
    <List
      itemLayout="vertical"
      header={<SearchHeader></SearchHeader>}
      size="small"
      pagination={{
        pageSize: 5,
      }}
      dataSource={results}
      renderItem={(item, index) => (
        <>
          <a href={`${getLangPrefix(lang)}/products/${item.slug}`}>
            <ResultItem key={index + item.name}>
              <ResultImage
                src={item.variations[0] && item.variations[0].image}
              />
              <ResultLink href={item.href}>{item.name}</ResultLink>
            </ResultItem>
          </a>
        </>
      )}
    />
  );
}
