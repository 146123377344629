import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Link from "next/link";
import { bindActionCreators } from "redux";

import translations from "../../config/translations";
import LanguageContext from "../../contexts/Language";

import FormProduct from "./formProduct";
import { clearCart } from "../../store/cart/actions";

import {
  MAIN_PADDING,
  MAIN_PADDING_2,
  MAIN_COLOR,
  BREAKPOINTS,
} from "../../constants";
import Icon, { InboxOutlined } from "@ant-design/icons";

import {
  Form,
  Select,
  Button,
  Upload,
  message,
  Col,
  Input,
  Result,
  Checkbox,
} from "antd";
import general from "../../config/general";

import CustomLink from "../custom-link";

const { Dragger } = Upload;
const { Option } = Select;

const ContactForm = styled.div`
  width: 900px;
  height: auto;
  max-width: 900px;
  background-color: #fff;
  padding: ${MAIN_PADDING};
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;

  @media (max-width: ${BREAKPOINTS.MD}) {
    width: 100%;
    height: auto;
    padding: 20px;
  }
`;

const ContactFormTitle = styled.h1`
  color: ${MAIN_COLOR};
  font-size: 30px;
  font-weight: 300;
`;

const ProductInCardWrapper = styled.div`
  height: auto;
  padding-top: 20px;
  border-top: 2px solid #3c41dc;
  border-bottom: 2px solid #3c41dc;
  margin-bottom: 40px;
`;

const ProductsList = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  margin-top: 20px;
`;
const Product = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 4fr;
  column-gap: 40px;
  border-bottom: solid 1px #e8e8e8;
  padding-bottom: 30px;
  margin-bottom: 30px;
`;

const ProductCartTitle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const IconText = ({ type, text }) => (
  <span>
    <Icon type={type} style={{ marginRight: 8 }} />
    {text}
  </span>
);

const ContactFormComponent = ({
  form,
  itemsInCart,
  openModalProductId,
  clearCart,
}) => {
  const lang = useContext(LanguageContext);
  const [formSent, setFormSent] = useState(false);
  const [formSentError, setFormSentError] = useState(false);
  const [formSendLoading, setFormSendLoading] = useState(false);
  const [havePermission, setHavePermission] = useState(false);

  const onFinish = async (values) => {
    setFormSendLoading(true);

    const formData = new FormData();
    const products = Object.values(itemsInCart).map((item) => ({
      id: item.id,
      quantity: item.quantity,
      name: item.title,
      delivery_date: item.deliveryDate,
      language: lang,
      number_of_printed_colors: item.numberOfColors,
      uploaded_image_urls:
        item.uploadedImagesData && item.uploadedImagesData.map((d) => d.url),
    }));

    formData.append("products", JSON.stringify(products));

    for (const valueKey in values) {
      formData.append(valueKey, values[valueKey]);
    }
    
    try {
      const resp = await fetch(`${general.API_URL}/wp-json/api/v2/query`, {
        method: "POST",
        body: formData,
      });

      setFormSendLoading(false);

      if (resp.status === 200) {
        clearCart();

        return setFormSent(true);
      }

      setFormSentError(true);
    } catch (error) {
      setFormSendLoading(false);
      setFormSentError(true);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
    labelAlign: "left",
  };

  return (
    <ContactForm>
      {formSent || formSentError ? (
        <Result
          status={formSentError ? "error" : "success"}
          title={
            formSentError
              ? translations[
                  "Oh no! Something went wrong. Please refresh the page!"
                ][lang]
              : translations["Your order has been sent to us!"][lang]
          }
          subTitle={
            formSentError
              ? ""
              : translations["We will contact you as soon as possible"][lang]
          }
          extra={[
            <CustomLink href={{ pathname: "/" }} as={"/"}>
              <Button type="primary" key="console">
                {translations["Go back to homepage"][lang]}
              </Button>
            </CustomLink>,
          ]}
        />
      ) : (
        <>
          <ContactFormTitle>
            {translations["Contact form"][lang]}
          </ContactFormTitle>
          <Form
            {...formItemLayout}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <ProductInCardWrapper>
              <ProductCartTitle>
                <h3>{translations["Products in cart"][lang]}:</h3>
                <Button size="small" danger onClick={() => clearCart()}>
                  {translations["Clear the cart"][lang]}
                </Button>
              </ProductCartTitle>

              <ProductsList>
                {Object.values(itemsInCart)
                  .filter(
                    (product) =>
                      !openModalProductId || product.id === openModalProductId
                  )
                  .map((product) => (
                    <FormProduct product={product} />
                  ))}
              </ProductsList>
            </ProductInCardWrapper>

            <Form.Item
              label={translations["Company name"][lang]}
              name="company"
            >
              <Input placeholder="Company name" />
            </Form.Item>
            <Form.Item
              label={translations["Contact person"][lang]}
              name="name"
              rules={[
                {
                  required: true,
                  message:
                    translations["Please input the contact person name"][lang],
                },
              ]}
            >
              <Input placeholder={translations["Contact person name"][lang]} />
            </Form.Item>
            <Form.Item
              label={translations["Email"][lang]}
              name="email"
              rules={[
                {
                  type: "email",
                  message: translations["The input is not valid E-mail!"][lang],
                },
                {
                  required: true,
                  message: translations["Please input your E-mail!"][lang],
                },
              ]}
            >
              <Input placeholder={translations["E-mail"][lang]} />
            </Form.Item>
            <Form.Item
              label={translations["Phone nr"][lang]}
              name="phone"
              rules={[
                {
                  required: true,
                  message:
                    translations["Please insert your phone number"][lang],
                },
              ]}
            >
              <Input placeholder={translations["Phone nr"][lang]} />
            </Form.Item>
            <Form.Item
              label={translations["Additional comments"][lang]}
              name="additional_comments"
            >
              <Input placeholder={translations["Additional comments"][lang]} />
            </Form.Item>

            <Form.Item
              label={translations["Permission to store data"][lang]}
              name="agree_store"
            >
              <Checkbox onChange={() => setHavePermission(!havePermission)}>
                {
                  translations[
                    "I give permission to store the data provided information in order to fulfill the order"
                  ][lang]
                }
              </Checkbox>
            </Form.Item>

            <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={!havePermission}
              >
                {translations["Send the query"][lang]}{" "}
                {formSendLoading ? translations["Loading"][lang] : ""}
              </Button>
            </Form.Item>
          </Form>
        </>
      )}
    </ContactForm>
  );
};

const mapStateToProps = (state) => ({
  itemsInCart: state.cart,
  openModalProductId: state.modal.id,
});

const mapDispatchToProps = (dispatch) => {
  return {
    clearCart: bindActionCreators(clearCart, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactFormComponent);
