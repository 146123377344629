import React, { useState, useEffect, useRef, useContext } from "react";
import Link from "next/link";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import styled from "styled-components";
import Router, { useRouter } from "next/router";
import _ from 'lodash';

import translations from "../config/translations";
import LanguageContext from "../contexts/Language";

import LoadingBar from "react-top-loading-bar";

import { MAIN_COLOR, BREAKPOINTS } from "../constants";
import isMobile from "../hooks/is-mobile";
import useDebouncedEffect from "../hooks/use-debounced-effect";

import { openModal, closeModal } from "../store/modal/actions";

import { ShoppingCart } from "@styled-icons/feather";
import { Search as SearchIcon } from "@styled-icons/evil";
import { MenuAltLeft as MenuIcon } from "@styled-icons/boxicons-regular";

import SearchResultsComponent from "./search-results";
import { Input, Menu } from "antd";
import { Modal } from "antd";

import { KeyboardArrowRight } from "styled-icons/material";

import LanguageMenu, {LanguageMenuMobile} from "./language-menu";

import general from "../config/general";
import { shouldRedirect } from '../helpers/should-redirect'
import CustomLink from "./custom-link";

const { SubMenu } = Menu;
const { Search } = Input;

const headerHeight = "80px";
const headerHeightMobile = "50px";

const Logo = styled.img`
  width: 150px;
  margin-right: 15px;
  cursor: pointer;
`;

const HeaderWrapper = styled.div`
  position: relative;
  height: ${headerHeight};

  @media (max-width: ${BREAKPOINTS.MD}) {
    height: ${headerHeightMobile};
  }
`;

const HeaderFixed = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.13);
`;

const HeaderInnerDesktop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeight};
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 35px;

  @media (max-width: ${BREAKPOINTS.MD}) {
    display: none;
  }
`;

const HeaderInnerMobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${headerHeightMobile};
  max-width: 1200px;
  margin: -5px auto 0;
  padding: 0 10px 0 0;

  @media (min-width: ${BREAKPOINTS.MD}) {
    display: none;
  }
`;

const MenuIconMobile = styled.div`
  width: ${headerHeightMobile};
  height: ${headerHeightMobile};
  background-color: ${MAIN_COLOR};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

const MenuIconWhite = styled(MenuIcon)`
  width: 30px;
  height: 30px;
  color: #fff;
`;

const HeaderInnerLeft = styled.div`
  display: flex;
  align-items: center;
  min-width: 50%;
`;

const HeaderInnerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const MenuLink = styled.a`
  text-decoration: none;
  margin-right: 24px;
  color: #000;

  &:hover {
    color: ${MAIN_COLOR};
  }
`;

const MenuMobileLeft = styled.div`
  display: flex;
  align-items: center;
`;

const SearchBox = styled.form`
  position: relative;
`;

const SearchInput = styled.input`
  width: 70%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  background-image: url("https://image.flaticon.com/icons/png/512/126/126474.png");
  background-position: 10px 10px;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  padding: 12px 20px 12px 40px;
  -webkit-transition: width 0.4s ease-in-out;
  transition: width 0.4s ease-in-out;
  float: right;

  &:focus {
    width: 100%;
    outline: none;
  }
`;

const ShoppingCartWrapper = styled.div`
  width: auto;
  height: auto;
  display: block;
  position: relative;

  ${(props) =>
    props.nrOfItems &&
    `
    &::after {
      display: block;
      content: "${props.nrOfItems}";
      width: 20px;
      height: 20px;
      border-radius: 20px;
      background-color: ${MAIN_COLOR};
      top: 15px;
      left: 30px;
      position: absolute;
      color: #fff;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
    }
  `}
`;

const BlueShoppingCart = styled(ShoppingCart)`
  color: #000;
  margin: 0 15px;

  &:hover {
    color: #1890ff;
  }
`;

const BlueSearchIcon = styled(SearchIcon)`
  width: 24px;
  height: 24px;
  color: #1890ff;
  margin: 0 15px;
  &:hover {
    color: white;
  }
`;

const SubmenuItem = styled.span`
  width: 250px;
  display: grid;
  grid-template-columns: 20px auto;
  align-items: center;

  &,
  a {
    color: #000;
  }

  a:hover {
    color: ${MAIN_COLOR};
  }
`;

const ArrowRight = styled(KeyboardArrowRight)`
  width: 20px;
  height: 20px;
  left: 0;
`;

const SearchResultsWrapper = styled.div`
  position: absolute;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px;
  border: solid 1px #eee;
  width: 400px;
  height: auto;
  right: 0;
  top: 50px;

  ${(props) =>
    props.inModal &&
    `
    position: relative;
  `}
`;

const CustomMenu = styled(Menu)`
  && {
    width: 100%;
    @media (max-width: ${BREAKPOINTS.MD}) {
      overflow-y: scroll;
    }
  }

  &&,
  .ant-menu-item.ant-menu-item,
  .ant-menu-horizontal.ant-menu-horizontal {
    border-bottom-color: transparent;
    border-right-color: transparent;
  }
`;

function CustomHeaderNavigation({
  productCategories,
  translations,
  lang,
  mode = "horizontal",
  disableSubMenu = false,
  showLang = false,
  pathname = null,
  asPath = null,
  translationURL,
  slugs={}
}) {
  return (
    <CustomMenu mode={mode} forceSubMenuRender={true}>
      {disableSubMenu ? (
        <CustomMenu.Item key="products-page">
          <CustomLink href={`/products`} locale={lang}>
            {translations["Products page"][lang]}
          </CustomLink>
        </CustomMenu.Item>
      ) : (
        <SubMenu
          title={
            <span className="submenu-title-wrapper">
              {/* <Icon type="setting" /> */}
              {translations["Products"][lang]}
            </span>
          }
        >
          {productCategories?.length > 0 &&
            productCategories.map((item) => (
              <Menu.Item key={item.id}>
                <SubmenuItem>
                  <ArrowRight />
                  <CustomLink href={`/categories/${item.slug}`} locale={lang}>
                    <a dangerouslySetInnerHTML={{ __html: item.name }} />
                  </CustomLink>
                </SubmenuItem>
              </Menu.Item>
            ))}
        </SubMenu>
      )}
      <CustomMenu.Item key="about-us">
        <CustomLink href={`/about`} locale={lang}>
          <a>{translations["About"][lang]}</a>
        </CustomLink>
      </CustomMenu.Item>
      <CustomMenu.Item key="blog">
        <CustomLink href={`/blog`} locale={lang} passHref>
          <a>{translations["Blog"][lang]}</a>
        </CustomLink>
      </CustomMenu.Item>
      <CustomMenu.Item key="portfolio">
        <CustomLink href={`/portfolio`} locale={lang} passHref>
          <a>{translations["Portfolio"][lang]}</a>
        </CustomLink>
      </CustomMenu.Item>
      <CustomMenu.Item key="contact-us">
        <CustomLink href={`/contact`} locale={lang}>
          <a>{translations["Contact us"][lang]}</a>
        </CustomLink>
      </CustomMenu.Item>
      {showLang && (
        <LanguageMenuMobile slugs></LanguageMenuMobile>
      )}
    </CustomMenu>
  );
}

function Header({
  productCategories,
  itemsInCart,
  openModal,
  closeModal,
  slugs = {},
}) {
  const router = useRouter();
  const { pathname, asPath } = useRouter();
  const lang = useContext(LanguageContext);

  const [hidden, setHidden] = useState(true);
  const [productResults, setProductResults] = useState([]);
  const [productSearchLoading, setProductSearchLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [redirectionsData, setRedirectionsData] = useState([]);
  const [nrOfItemsInCart, setNrOfItemsInCart] = useState(0);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileSearchOpen, setIsMobileSearchOpen] = useState(false);
  const isCurrentlyMobile = isMobile();

  useEffect(() => {
    setNrOfItemsInCart(Object.values(itemsInCart).length);
  }, [itemsInCart]);

  const loadingBarRef = useRef(null);
  const TheLoadingBar = () => (
    <LoadingBar ref={loadingBarRef} height={5} color="#1890ff" />
  );

  let timeout;
  const getSlugAsUrl = () => {
    const switcherLocale = lang === "et" ? "en" : "et";
    const slug = slugs && slugs[switcherLocale];

    return slug || asPath;
  };
  const hasTranslation = () => {
    const switcherLocale = lang === "et" ? "en" : "et";

    return slugs[lang] ? !!slugs[switcherLocale] : true;
  };

  const fetchOptionsData = async () => {
    const optionsData = await (await fetch(`${general.API_URL}/wp-json/api/v2/options`)).json().catch(err => console.error(err)) || [];
    setRedirectionsData(optionsData.redirections);
  }

  useEffect(() => {
    

    Router.events.on("routeChangeStart", (url) => {
      loadingBarRef &&
        loadingBarRef.current &&
        loadingBarRef.current.continuousStart();
    });
    Router.events.on("routeChangeComplete", () => {
      loadingBarRef &&
        loadingBarRef.current &&
        loadingBarRef.current.complete();
      closeModal();
      setShowResults(false);
      setIsMobileMenuOpen(false);
    });
    Router.events.on("routeChangeError", () => {
      loadingBarRef &&
        loadingBarRef.current &&
        loadingBarRef.current.complete();
      closeModal();
      setShowResults(false);
    });

    fetchOptionsData();
  }, []);

  useEffect(() => {
    const redirectData = shouldRedirect(redirectionsData, asPath);
    if (redirectData) {
      const redirectMethod = redirectData.code === '301' ? 'replace' : 'push';
      return router[redirectMethod](redirectData.destination);
    }
  }, [asPath, redirectionsData])

  const handleSearch = async (value) => {
    try {
      const resp = await fetch(
        `${general.API_URL}/wp-json/api/v2/search/${lang}?term=${value}`
      );

      if (resp.status !== 200) {
        throw new Error("Could not fetch responses");
      }

      const { products } = resp && (await resp.json());

      setProductSearchLoading(false);
      setProductResults(products);
    } catch (error) {
      setProductSearchLoading(false);
      setProductResults([]);
    }
  };

  const handleSearchTermChange = (term) => {
    if (!term || term.length < 3) {
      setShowResults(false);

      return;
    }

    setShowResults(true);
    setProductSearchLoading(true);

    handleSearch(term);
  }

  const translationURL = `${lang === "et" ? "/en" : ""}${getSlugAsUrl() || ""}`;

  useDebouncedEffect(() => handleSearchTermChange(searchTerm), [searchTerm], 1000);

  return (
    <HeaderWrapper>
      <HeaderFixed>
        <TheLoadingBar></TheLoadingBar>
        {!isCurrentlyMobile ? (
          <HeaderInnerDesktop>
            <HeaderInnerLeft>
              <CustomLink href={`/`} locale={lang}>
                <a>
                  <Logo src="/static/logo.svg" />
                </a>
              </CustomLink>
              <CustomHeaderNavigation
                productCategories={productCategories}
                translations={translations}
                lang={lang}
                pathname={pathname}
                translationURL={translationURL}
              />
            </HeaderInnerLeft>
            <HeaderInnerRight>
              <SearchBox>
                <Search
                  placeholder={translations["Search for products"][lang]}
                  onChange={(event) => {
                    setSearchTerm(event.target.value)
                  }}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13 ) {
                      event.preventDefault();
                    }
                  }}
                  loading={productSearchLoading}
                />
                {showResults && (
                  <SearchResultsWrapper>
                    <SearchResultsComponent
                      onClickOnClose={() => setShowResults(false)}
                      results={productResults}
                    />
                  </SearchResultsWrapper>
                )}
              </SearchBox>
              <ShoppingCartWrapper
                onClick={() => openModal()}
                nrOfItems={nrOfItemsInCart}
              >
                <BlueShoppingCart size="24" />
              </ShoppingCartWrapper>
              <LanguageMenu slugs={slugs} />
            </HeaderInnerRight>
          </HeaderInnerDesktop>
        ) : (
          <HeaderInnerMobile>
            <Modal
              style={{ top: 10 }}
              visible={isMobileMenuOpen}
              onCancel={() => setIsMobileMenuOpen(false)}
              footer={[]}
              bodyStyle={{
                height: 'auto'
              }}
            >
              <CustomHeaderNavigation
                productCategories={productCategories}
                translations={translations}
                lang={lang}
                mode="vertical"
                disableSubMenu={true}
                showLang={hasTranslation()}
                pathname={pathname}
                asPath={asPath}
                translationURL={translationURL}
                slugs={slugs}
              />
            </Modal>
            <MenuMobileLeft>
              <MenuIconMobile onClick={() => setIsMobileMenuOpen(true)}>
                <MenuIconWhite></MenuIconWhite>
              </MenuIconMobile>

              <CustomLink href={`/`}>
                <a>
                  <Logo src="/static/logo.svg" />
                </a>
              </CustomLink>
            </MenuMobileLeft>

            <HeaderInnerRight>
              <Modal
                style={{ top: 10 }}
                visible={isMobileSearchOpen}
                onCancel={() => setIsMobileSearchOpen(false)}
                footer={[]}
              >
                <SearchBox style={{ marginTop: 20 }}>
                  <Search
                    placeholder={translations["Search for products"][lang]}
                    onChange={(event) => setSearchTerm(event.target.value)}
                    loading={productSearchLoading}
                  />
                  {showResults && (
                    <SearchResultsComponent
                      onClickOnClose={() => setIsMobileSearchOpen(false)}
                      results={productResults}
                    />
                  )}
                </SearchBox>
              </Modal>
              <BlueSearchIcon onClick={() => setIsMobileSearchOpen(true)} />
              <ShoppingCartWrapper
                onClick={() => openModal()}
                nrOfItems={nrOfItemsInCart}
              >
                <BlueShoppingCart size="24" />
              </ShoppingCartWrapper>
            </HeaderInnerRight>
          </HeaderInnerMobile>
        )}
      </HeaderFixed>
    </HeaderWrapper>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: bindActionCreators(openModal, dispatch),
    closeModal: bindActionCreators(closeModal, dispatch),
  };
};

const mapStateToProps = (state) => ({
  itemsInCart: state.cart,
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
