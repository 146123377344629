import "antd/dist/antd.css";

import { connect } from "react-redux";
import LanguageContext from "../contexts/Language";

import ContactForm from "./contact-form/index";
import Modal from "./modal";

import styled from "styled-components";
import Header from "./header";
import Footer from "./footer";
import CookieConsentDialog from "./cookie-consent/dialog";

const MainLayout = styled.div`
  font-family: "Open Sans", sans-serif;
`;

const LayoutWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1600px;
`;

function Layout(props) {
  return (
    <LanguageContext.Provider value={props.locale}>
      <MainLayout>
        <CookieConsentDialog/>
        {props.open ? (
          <Modal>
            <ContactForm />
          </Modal>
        ) : null}
        <LayoutWrapper>
          <Header
            productCategories={props.productCategoriesData}
            slugs={props.slugs}
          />
          {props.children}
        </LayoutWrapper>
        <Footer items={props.footerData} />
      </MainLayout>
    </LanguageContext.Provider>
  );
}

const mapStateToProps = (state) => ({
  open: state.modal.open,
});

export default connect(mapStateToProps)(Layout);
